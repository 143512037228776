import React from 'react'

function Footer() {
    return (
        <footer className='"footer p-10 bg-gray-700 text-primary-content footer-center text-white'>
            <div className='mt-3'>
               
            </div>
        </footer>
    )
}

export default Footer
